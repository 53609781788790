<template>
  <div class="page-register" :class="this.page === 0 ? 'force-height' : ''">
    <div class="register-card">
      <div class="card-header" v-if="page == 0">
        <h4 class="card-title">Sign Up</h4>
      </div>
      <div class="card-body" v-if="page == 0">
        <form @submit.prevent="signupPageOne">
          <InputField
            class="signup-input-field"
            v-for="[key, data] in Object.entries(pageOneForm)"
            :data="data"
            :key="key"
            :ref="key"
            :dataName="key"
            @update:modelValue="dynamicChecking(data, key)"
            v-model="pageOneForm[key].data"></InputField>
          <div class="button-container">
            <button class="button button-dark" :disabled="isLoading">
              Sign Up
            </button>
            <!-- <button class="button button-light" @click="page++">skip</button> -->
            <!-- //!remove this later -->
          </div>
        </form>
      </div>
      <div class="flex-center-column" v-if="page == 1">
        <div class="instructions">
          <h4 class="text subtitle">Success!</h4>
          <p class="text">
            You have been registered! Please check your email for an activation
            email before you can make posts or comments.
          </p>
          <p class="text">
            While you do that, give yourself an alias, picture and bio that
            others will see you as.
          </p>
        </div>
        <form class="flex-center-column" @submit.prevent="signupPageTwo">
          <InputField
            class="input alias-input"
            :data="pageTwoForm.alias"
            dataName="alias"
            ref="alias"
            v-model="pageTwoForm.alias.data"></InputField>
          <div class="picture-input-container">
            <picture-input-field
              v-model="image"
              @error="showImageError"
              @newfile="clearImageError"
              ref="imageInput"
              :keepSquare="true"></picture-input-field>
            <error-message ref="imageerror"></error-message>
          </div>
          <InputField
            class="input bio-input"
            :data="pageTwoForm.bio"
            dataName="bio"
            ref="bio"
            inputType="textarea"
            v-model="pageTwoForm.bio.data"></InputField>
          <div class="button-container">
            <button
              class="button button-primary"
              :disabled="isLoading"
              type="submit">
              Finish
            </button>
            <button
              class="button button-light"
              :disabled="isLoading"
              @click="goToHome">
              Skip For Now
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InputField from "@/components/InputField.vue";
import PictureInputField from "../components/PictureInputField.vue";
import ErrorMessage from "../components/basic/ErrorMessage.vue";
export default {
  name: "SignUp",
  components: {
    InputField,
    PictureInputField,
    ErrorMessage,
  },
  data() {
    return {
      pageOneForm: {
        username: {
          label: "Username",
          required: true,
          help_text:
            "Give yourself a unique username. It will be used to log in, and in the url of your profile. You can choose a different Alias that people will see you as later.",
          type: "text",
          data: "",
        },
        password: {
          label: "Password",
          required: true,
          help_text:
            "Choose a strong password. Must be at least 8 characters long and not a common poassword. Symbols and stuff doesn't matter, just make it long.",
          type: "password",
          data: "",
        },
        email: {
          label: "Email",
          required: true,
          help_text:
            "Enter a real email. It won't be shown to others, and will be needed to activate your account.",
          type: "email",
          data: "",
        },
        date_of_birth: {
          label: "Date of Birth",
          required: true,
          help_text: "Enter your date of birth. It won't be shown to others.",
          type: "date",
          data: "",
        },
        signup_code: {
          label: "Invite Code",
          required: true,
          help_text:
            "For the time being, a special code is required to create an account",
          type: "text",
          data: "",
        },
      },
      pageTwoForm: {
        alias: {
          label: "Alias",
          required: false,
          help_text:
            "Choose an interesting name for yourself. This will be shown to others in your posts and comments.",
          data: "",
        },
        bio: {
          label: "Bio",
          required: false,
          help_text: "Describe yourself to others.",
          data: "",
        },
      },
      page: 0, //we break up the form into 3 sections to make it easier on the eyes. and page 2 and 3 will mostly be optional
      image: {},
      isLoading: false,
      usernameCheckTimout: 0,
    };
  },
  methods: {
    dynamicChecking(data, key) {
      if (key === "username") {
        clearTimeout(this.usernameCheckTimout);
        if (typeof data.data === "string" && data.data.length >= 4) {
          this.usernameCheckTimout = setTimeout(() => {
            this.$refs.username[0].showValidation = true;
            this.$refs.username[0].validated = false;
            this.$refs.username[0].invalid = false;
            this.$refs.username[0].loading = true;
            axios
              .get("/api/v1/users/check/", { params: { name: data.data } })
              .then((res) => {
                console.log(res);
                this.$refs.username[0].loading = false;
                if (res.status === 200) {
                  this.$refs.username[0].validated = true;
                } else {
                  this.$refs.username[0].invalid = true;
                }
              })
              .catch((err) => {
                console.log(err);

                this.$refs.username[0].invalid = true;
                this.$refs.username[0].loading = false;
              });
          }, 1000);
        } else {
          this.$refs.username[0].showValidation = false;
          this.$refs.username[0].validated = false;
          this.$refs.username[0].invalid = false;
          this.$refs.username[0].loading = false;
        }
      }
    },
    showImageError(error) {
      this.$refs.imageerror.addError(error);
    },
    clearImageError() {
      this.$refs.imageerror.clearError();
    },
    setLoading(boolean) {
      this.isLoading = boolean;
      this.$store.commit("setLoading", boolean);
    },
    signupPageOne() {
      //remove all other tokens
      this.$store.commit("removeToken");
      this.$store.commit("removeUserData");
      this.$store.commit("setAuthentication", false);
      let data = {};
      Object.keys(this.pageOneForm).forEach((key) => {
        data[key] = this.pageOneForm[key].data;
      });
      this.setLoading(true);
      axios
        .post("/api/v1/users/", data)
        .then((res) => {
          if (res.status === 201) {
            axios.post("/api/v1/token/login", data).then((res) => {
              this.$store.commit("setToken", res.data.auth_token);
              this.$store.dispatch("initialize");
              axios.get("/api/v1/users/me").then((res) => {
                this.setLoading(false);
                this.$store.commit("setUserData", res.data);
                this.$notify({
                  title: "Registered!",
                  body: `Hello ${res.data.username}, you have been successfully registered!`,
                  icon: "",
                  type: "success",
                });
                this.page++;
              });
            });
          }
        })
        .catch((err) => {
          if (!err.response || !err.response.data) {
            let customError = "There's an issue, pleasy try again later.";
            if (this.$refs.username[0].shownErrors.includes(customError)) {
              this.$refs.username[0].shake();
            } else {
              this.$refs.username[0].shownErrors.push(customError);
            }
          }
          this.setLoading(false);
          this.isLoading = false;
          Object.keys(err.response.data).forEach((key) => {
            err.response.data[key].forEach((error) => {
              if (!this.$refs[key][0]) {
                key = "username";
              }
              if (this.$refs[key][0].shownErrors.includes(error)) {
                this.$refs[key][0].shake();
              } else {
                this.$refs[key][0].shownErrors.push(error);
              }
            });
          });
        });
    },
    signupPageTwo() {
      let data = {};
      Object.keys(this.pageTwoForm).forEach((key) => {
        data[key] = this.pageTwoForm[key].data;
      });
      let fd = new FormData();
      console.log(this.image);
      if (this.image instanceof File) {
        fd.append("profile_image", this.image);
      }
      if (this.pageTwoForm.alias.data) {
        fd.append("alias", this.pageTwoForm.alias.data);
      }
      if (this.pageTwoForm.bio.data) {
        fd.append("bio", this.pageTwoForm.bio.data);
      }
      this.setLoading(true);
      axios
        .patch("/api/v1/users/me/", fd)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            this.$store.commit("setUserData", res.data);
            this.$notify({
              title: "Welcome!",
              body: `${res.data.alias}, go ahead and explore!`,
              icon: "",
              type: "success",
            });
            this.goToHome();
          }
          this.setLoading(false);
        })
        .catch((err) => {
          if (!err.response || !err.response.data) {
            let customError = "There's an issue, pleasy try again later.";
            if (this.$refs.alias.shownErrors.includes(customError)) {
              this.$refs.alias.shake();
            } else {
              this.$refs.alias.shownErrors.push(customError);
            }
          }
          this.setLoading(false);
          this.isLoading = false;
          Object.keys(err.response.data).forEach((key) => {
            err.response.data[key].forEach((error) => {
              if (!this.$refs[key]) {
                key = "alias";
              }
              if (this.$refs[key].shownErrors.includes(error)) {
                this.$refs[key].shake();
              } else {
                this.$refs[key].shownErrors.push(error);
              }
            });
          });
        });
    },
    goToHome() {
      const toPath = this.$route.query.to || "/";
      this.$router.push(toPath);
    },
    getInput(thing) {
      console.log(this.key, thing);
    },
  },
  mounted() {
    document.title = "sign up | icandoathing";
  },
};
</script>

<style lang="scss" scoped>
.page-register {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.register-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $grey-300;
  border-radius: $border-radius;
  padding: 1rem;
  min-width: $navbar-width-lg;
  margin: $item-margin;
}
.flex-center-column {
  align-items: center;
}
.input {
  width: 100%;
}
.card-title {
  font-size: 2rem;
  color: $primary-600;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
  text-transform: lowercase;
}
.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 1rem;
  column-gap: 0.5rem;
}
.picture-input-container {
  background-color: white;
  width: 18rem;
  height: 15rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  margin: 1rem;
  flex-direction: column;
  & > * {
    width: 100%;
  }
}
.text {
  color: $primary;
}
.subtitle {
  color: $primary-300;
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
}
.force-height {
  min-height: 100vh; //the height doesnt re-render when the component becomes larger, so the footer overlaps the signup page
}
.alias-input {
  max-width: 50ch;
}
.bio-input {
  max-width: 50ch;
}
.instructions {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 75ch;
  flex-direction: column;
}
.signup-input-field {
  width: 35ch;
}
@media screen and (max-width: $switch-width) {
  .picture-input-container {
    width: 70vw;
    height: 60vw;
    margin: 1rem 0;
  }
  .register-card {
    margin: 1rem;
  }
}
</style>
